<!--
 * @Author: qinlifang 1171533972@qq.com
 * @Date: 2023-10-30 23:01:35
 * @LastEditors: 1171533972@qq.com
 * @LastEditTime: 2024-01-31 17:32:06
 * @FilePath: \matrix-home\src\components\NavBar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="top">
    <div class="content">
      <div class="content_t">
        <a href="https://metf.me/">  <img src="../assets/img/home/00.png" alt /></a>
       
        <div>
          <ul>
            <li @click="gohome">{{$t('home.Home')}}</li>
            <li @click="goProduct">{{$t('home.Product')}}</li>
            <li @click="gowhy">{{$t('home.WhyMatrixs')}}</li>
             <li @click="goinves">{{$t('home.Strategies')}}</li>
            <li @click="goPartner">{{$t('home.Partner')}}</li>
            <li @click="goComm">{{$t('home.Community')}}</li>
            <a href="mailto:support@matrixfund.pro"><li>{{$t('home.ContactUs1')}}</li></a>
          </ul>
        
        </div>
        <!-- <div>
          <div class="button"  v-show="lanZh"   @click="changeLanguage('zh-CN')">中文</div>
        <div class="button"   v-show="lanEn"  @click="changeLanguage('en')">English</div>

        </div> -->
        
        <!-- <van-dropdown-menu>
  <van-dropdown-item v-model="value1" :options="option1" />
 
</van-dropdown-menu> -->
      </div>
    </div>
  </div>
</template>
<script>
import i18n from "@/utils/lang";
import { DropdownMenu, DropdownItem } from 'vant';
export default {
   components: {
 
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
   
  },
  data() {
    return {
       value1: 0,
      value2: 'a',
       lanZh: false,
      lanEn: false,
      option1: [
        { text: '中文', value: 0 },
        { text: '英文', value: 1 },
       
      ],
      // option2: [
      //   { text: '默认排序', value: 'a' },
      //   { text: '好评排序', value: 'b' },
      //   { text: '销量排序', value: 'c' },
      // ],
      isOpen: false, // 是否展开选项
      isOpen1: false, // 是否展开选项
      isOpen2: false, // 是否展开选项
      isOpen3: false, // 是否展开选项
      isOpen4: false, // 是否展开选项
      selectedOption: "", // 当前选中的选项
      selectedOption1: "", // 当前选中的选项
      selectedOption2: "", // 当前选中的选项
      selectedOption3: "", // 当前选中的选项
      selectedOption4: i18n.t("top.Docs"), // 当前选中的选项
      options: [
        // i18n.t("top.Learn"),
        i18n.t("bottom.Basics"),
        i18n.t("bottom.CyberDAOwhat"),
        i18n.t("bottom.Background"),
        // i18n.t("bottom.Consultants"),
        i18n.t("bottom.Partners"),
        i18n.t("bottom.CyberDAO")
      ], // 选项列表
      options1: [
        // i18n.t("bottom.Governance"),
        i18n.t("bottom.Protocol"),
        // i18n.t("bottom.POD"),
        i18n.t("bottom.Token"),
        i18n.t("bottom.Pool"),
        i18n.t("bottom.Nodes"),
        // i18n.t("bottom.Vote")
      ], // 选项列表
      options2: [
        // i18n.t("bottom.Ecosystem"),
        i18n.t("bottom.Wallet"),
        i18n.t("bottom.Sapce"),
        i18n.t("bottom.Grantsl")
      ], // 选项列表
      options3: [
        // i18n.t("bottom.Foundation"),
        i18n.t("bottom.Contact"),
        i18n.t("bottom.Opportunities")
      ], // 选项列表
      options4: [i18n.t("top.Docs")] // 选项列表
    };
  },

  mounted() {
     if (localStorage.getItem("languageSet") == "zh-CN") {
        this.lanEn = true;
        this.lanZh = false;
      } else if (localStorage.getItem("languageSet") == "en") {
        this.lanZh = true;
        this.lanEn = false;
      } else {
        this.lanZh = true;
        this.lanEn = false;
      }
   
  },
  destroyed() {},

  methods: {
       // 切换语言
    changeLanguage(val) {
      this.$i18n.locale = val;
      localStorage.setItem("languageSet", val);
      if (val == "zh-CN") {
        this.lanEn = true;
        this.lanZh = false;
       
      }
      if (val == "en") {
        this.lanZh = true;
        this.lanEn = false;
       
      }
    },
    gohome(){
        this.$emit("scrollToSection", "home");

    },
     goProduct(){
        this.$emit("scrollToSection", "Product");

    },
     gowhy(){
        this.$emit("scrollToSection", "why");

    },
    
     goinves(){
        this.$emit("scrollToSection", "inves");

    },
      goPartner(){
        this.$emit("scrollToSection", "Partner");

    },
    goComm(){
      this.$emit("scrollToSection", "Comm");

    },
  goDoc(){
    window.open('https://cyberdao.oss-cn-hangzhou.aliyuncs.com/assets/Documents.zip')

  },

    toggleDropdown() {
  
      this.isOpen1 = false;
      this.isOpen2 = false;
      this.isOpen3 = false;
      this.isOpen4 = false;
      this.isOpen = !this.isOpen; // 切换展开状态
    },
    toggleDropdown1() {
      this.isOpen = false;
      this.isOpen2 = false;
      this.isOpen3 = false;
      this.isOpen4 = false;
      this.isOpen1 = !this.isOpen1; // 切换展开状态
    },
    toggleDropdown2() {
      this.isOpen1 = false;
      this.isOpen = false;
      this.isOpen3 = false;
      this.isOpen4 = false;
      this.isOpen2 = !this.isOpen2; // 切换展开状态
    },
    toggleDropdown3() {
      this.isOpen1 = false;
      this.isOpen = false;
      this.isOpen2 = false;
      this.isOpen4 = false;
      this.isOpen3 = !this.isOpen3; // 切换展开状态
    },
    toggleDropdown4(option) {
      this.isOpen1 = false;
      this.isOpen = false;
      this.isOpen2 = false;
      this.isOpen3 = false;
      // if (option == i18n.t("top.Docs")) {
      //   this.$router.push("/covemances");
      // }
      this.isOpen4 = !this.isOpen4; // 切换展开状态
    },
    selectOption(option) {
      console.log(option)
      // if (option == i18n.t("top.Learn")) {
      //   if (window.location.href.split("/")[3] != "") {
      //     this.$router.push("/");
      //   }
      // }
      if (option == i18n.t("bottom.Basics")) {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSection", "home");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: "home" } });
         
        }
      }
       if (option == i18n.t("bottom.CyberDAOwhat")) {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSection", "Basics");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: "Basics" } });
        }
      }
       if (option == i18n.t("bottom.Background")) {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSection", "Background");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: "Background" } });
        }
      }
       if (option == i18n.t("bottom.Consultants")) {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSection", "skynet");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: "skynet" } });
        }
      }
       if (option == i18n.t("bottom.Partners")) {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSection", "Partners");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: "Partners" } });
        }
      }
       if (option == i18n.t("bottom.CyberDAO")) {
       window.open('https://dao.cyber-dao.com')
      }
      this.selectedOption = option; // 选中选项
      this.isOpen = false; // 关闭下拉选框
    },
    selectOption1(option) {
      console.log(option)
       if (option == i18n.t("bottom.Protocol")) {
        if (window.location.href.split("/")[3] == "covemances") {
          this.$emit("scrollToSection", "Protocol");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "covemances",params: { Basics: "Protocol" }   });
        }
      }
       if (option == i18n.t("bottom.Token")) {
        if (window.location.href.split("/")[3] == "govemances") {
          this.$emit("scrollToSection", "Token");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "govemances",params: { Basics: "Token" }   });
        }
      }
       if (option == i18n.t("bottom.Pool")) {
        if (window.location.href.split("/")[3] == "govemances") {
          this.$emit("scrollToSection", "CyberDAOPool");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "govemances",params: { Basics: "CyberDAOPool" }   });
        }
      }
      if (option == i18n.t("bottom.Nodes")) {
        if (window.location.href.split("/")[3] == "govemances") {
          this.$emit("scrollToSection", "global");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "govemances",params: { Basics: "global" }   });
        }
      }
       if (option == i18n.t("bottom.Vote")) {
        // if (window.location.href.split("/")[3] == "stakes") {
        //   this.$emit("scrollToSection", "Vote");
        // } else {
        //   // 非首页时跳转到首页的The Basics指定位置
        //   this.$router.push({ name: "stakes",params: { Basics: "Vote" }   });
        // }
      }
      this.selectedOption1 = option; // 选中选项
      this.isOpen1 = false; // 关闭下拉选框
    },
    selectOption2(option) {
      console.log(option, "option");
       if (option == i18n.t("bottom.Wallet")) {
        if (window.location.href.split("/")[3] == "ecosystems") {
          this.$emit("scrollToSection", "ecosystems");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "ecosystems",params: { Basics: "ecosystems" }   });
        }
      }
       if (option == i18n.t("bottom.Sapce")) {
        if (window.location.href.split("/")[3] == "ecosystems") {
          this.$emit("scrollToSection", "Sapce");
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "ecosystems",params: { Basics: "Sapce" }   });
        }
      }
      this.selectedOption2 = option; // 选中选项
      this.isOpen2 = false; // 关闭下拉选框
    },
    selectOption3(option) {
      if (option == i18n.t("bottom.Contact")) {
        window.open('mailto:support@cyber-dao.com')
      }
      this.selectedOption3 = option; // 选中选项
      this.isOpen3 = false; // 关闭下拉选框
    },
    selectOption4(option) {
      // console.log(option, "option");
      // if (option == i18n.t("top.Docs")) {
      //   this.$router.push("/covemances");
      // }
      this.selectedOption4 = option; // 选中选项
      this.isOpen4 = false; // 关闭下拉选框
    }
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "DINBold";
  src: url("@/utils/Montserrat-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "DINRegular";
  src: url("@/utils/Montserrat-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "DINMedium";
  src: url("@/utils/Montserrat-Medium.ttf") format("opentype");
}
.top {
  width: 100%;
  height: 80Px;
    padding: 20Px 280Px 20Px 280Px;
    box-sizing: border-box;
  background-color: #0A0D0F;
  font-family: "DINRegular";
 
 

  .content {
    width: 100%;
    height: 100%;
  
    .content_t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      ul{
         display: flex;
      align-items: center;
      justify-content: space-between;
        li{
          font-size: 14Px;
          color: #fff;
          margin-left: 37Px;
          cursor: pointer;
        }
      }
      .button{
        width: 119Px;
        height: 41Px;
        line-height: 41Px;
        text-align: center;
        border: 1Px solid  #fff ;
        color:#fff;
        font-size: 16Px;
        border-radius: 20px;
        font-family: "DINRegular";
        cursor: pointer;

      }
      .hidden {
        display: none;
      }
      img {
        width: 152Px;
        height: 40Px;
      }
      .dropdown {
        position: relative;
        display: inline-block;
        color: #fff;
      }

      .selected-option {
        height: 80Px;
        position: relative;
        display: inline-flex;
        align-items: center;
        // padding: 4Px 8Px 4Px 8Px;
        box-sizing: border-box;
        margin-right: 48Px;
        // background-color: #f0f0f0;
        cursor: pointer;
      }

      .selected-option span {
        font-size: 28Px;
        margin-right: 8Px;
      }

      .arrow {
        border: solid #fff;
        border-width: 0 2Px 2Px 0;
        display: inline-block;
        padding: 2Px;
        transform: rotate(45deg);
        transition: transform 0.2s;
      }

      .arrow-up {
        transform: rotate(-135deg);
      }

      .options {
        display: inline-block;
        padding:10Px;
        // margin: 0;
        box-sizing: border-box;
        list-style: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 206Px;
        color: #000 !important;
        // background-color: #080935;
        background-color: #fff;
        border: none;
        margin-left: -45%;
        border-radius: 13Px;
        // margin-top: 10Px;
        box-shadow: 0 4Px 6Px rgba(0, 0, 0, 0.1);
      } 

    //   .options::after{
    //     content: '';
    //      width: 50%; /* 或使用其他动态单位，如vw */  
    // height: auto; /* 让高度根据内容自动调整 */
    //   }

      .options li {
        font-size: 28Px;
        line-height: 54Px;
        padding-left: 18Px;
        padding-right: 18Px;

        box-sizing: border-box;
        cursor: pointer;
        min-width: 280Px;
        height: fit-content;
        text-align: left;
        //  background-color: #080935;
        overflow: auto;
      }

      .options li:hover {
        background-color: #f5f5f5;
        border-radius: 13Px;
      }
    }
    .content_c {
      text-align: center;

      .img2 {
        position: absolute;
        top: 15%;
        left: 36%;
        // transform: translate(-50%, -50%);
      }
      .text {
        font-size: 24Px;
        font-family: DINPro;
        font-weight: bold;
        color: #ffffff;
        line-height: 45Px;
        position: absolute;
        top: 42%;
        left: 32%;
        box-sizing: border-box;
        // transform: translate(-50%, -50%);
      }
      .title {
        font-size: 18Px;
        font-family: Oxanium;
        font-weight: 400;
        color: #dcdcdc;
        line-height: 20Px;
        position: absolute;
        top: 48%;
        left: 10%;
        padding-right: 97Px;
        box-sizing: border-box;
      }
      .lastitle {
        font-size: 18Px;
        font-family: Oxanium;
        font-weight: 400;
        color: #dcdcdc;
        line-height: 20Px;
        position: absolute;
        top: 66%;
        left: 10%;
        padding-right: 97Px;
        box-sizing: border-box;
      }
      .button {
        width: 441Px;
        height: 77Px;
        border: 3Px solid #ffffff;
        border-radius: 20Px;
        font-size: 28Px;
        font-family: Oxanium;
        font-weight: 600;
        color: #ffffff;
        line-height: 77Px;
        position: absolute;
        top: 86%;
        left: 30%;
        
      }
    }
  }
}
.navbar {
  position: fixed;
  top: 0;
  height: 108Px;
  width: 100%;
  color: #fff;
  padding: 10Px;
  box-sizing: border-box;
  background-image: url("../assets/img/page2/bgc519.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9999;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24Px;
  color: #fff;
  margin-left: 180Px;
  .img {
    width: 108Px;
    height: 108Px;
  }
}

nav {
  display: flex;
  margin-right: 180Px;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
}

nav ul li {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30Px;
}
nav ul li img {
  width: 20Px;
  height: 13Px;
}

nav ul li a {
  display: block;
  padding: 10Px;
  color: #fff;
  text-decoration: none;
  font-size: 26Px;
  font-weight: 600;
  font-family: Oxanium;
}
</style>
